import BaseTheme from './base-theme';

import CryptoJS_SHA256 from 'crypto-js/sha256';
import Accordion from '../accordion/accordion';
import AccordionItem from '../accordion/accordion-item';
import Fa from 'vue-fa';

export default {
    template   : require('./modal.vue'),
    components : {
        ...BaseTheme.components,
        'accordion': Accordion,
        'accordion-item': AccordionItem,
        Fa
    },
    inject     : {
        postAcceptCallback : 'postAcceptCallback',
        postRejectCallback : 'postRejectCallback'
    },
    props      : {
        isVisible             : false,
        submitLead            : false,
        strikePartialPartners : true,
    },
    data() {
        return {
            ...BaseTheme.data()
        };
    },
    computed   : {
        ...BaseTheme.computed,

        strikePartners() {
            // by default, strike the partners text if even one is unselected
            if (this.strikePartialPartners) {
                return !this.allPartnersSelected;
            }

            // strike the partners text only if none are selected
            return (!this.allPartnersSelected && this.selectedPartners.length === 0);
        }
    },
    methods    : {
        ...BaseTheme.methods,

        getTermsAndConditions(required) {
            const campaigns = this.lcGetCampaigns;

            let result = '';
            let hashes = {};

            campaigns.forEach(campaign => {
                let hash = CryptoJS_SHA256(campaign.consent.text);

                if (campaign.consent.required !== required || hashes.hasOwnProperty(hash)) {
                    return;
                }

                hashes[hash] = campaign.consent.text;
                result += campaign.consent.text;
            });

            return result;
        },

        toggleAllPartners() {
            this.allPartnersSelected = !this.allPartnersSelected;

            this.selectedPartners = [];

            if (this.allPartnersSelected) {
                for (const partner of this.storeGetPartners) {
                    if (this.selectedPartners.indexOf(partner.value) === -1) {
                        this.selectedPartners.push(partner.value);
                    }
                }
            }

            this.lcToggleCampaigns(this.allPartnersSelected);
        },

        togglePartner(event) {
            const partner = event.target.value;
            const index   = this.selectedPartners.indexOf(partner);

            if (index === -1) {
                this.selectedPartners.push(partner);
            } else {
                this.selectedPartners.splice(index, 1);
            }

            this.allPartnersSelected = (this.selectedPartners.length === this.storeGetPartners.length);
            this.lcToggleCampaigns(this.allPartnersSelected);
        },

		isAnySelected() {
			return this.allPartnersSelected
				|| this.selectedPartners.length > 0;
		},

        accept(event) {
            if (this.submitLead) {
                event.preventDefault();
            }

            this.$emit('closeModal', true);
            this.showPartners = false;
            this.postAcceptCallback();
        },

        close() {
            this.$emit('closeModal', false);
            this.showPartners = false;
            this.postRejectCallback();
        }
    }
};
