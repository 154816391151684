import BaseTheme from './base-theme';
import Logger    from '../../services/log-service.js';

import CryptoJS_SHA256 from 'crypto-js/sha256';
import {mapActions}    from 'vuex';
import Accordion from '../accordion/accordion';
import AccordionItem from '../accordion/accordion-item';
import Fa from 'vue-fa';

export default {
    template   : require('./check-box-v2.vue'),
    components : {
        ...BaseTheme.components,
        'accordion': Accordion,
        'accordion-item': AccordionItem,
        Fa
    },
    data() {
        return {
            ...BaseTheme.data(),
            requiredCampaigns         : [],
            optionalCampaigns         : [],
            selectedRequiredCampaigns : [],
            selectedOptionalCampaigns : [],
            currentCampaign           : 0,
            currentCampaignIds        : [],
            showOptionalCheckbox      : false,
            allPartnersSelected       : false,
            hasPartnersSelected       : false,
        };
    },
    computed   : {
        ...BaseTheme.computed
    },
    watch      : {
        lcLoaded : function (value) {
            if (value === true) {
                this.requiredCampaigns = this.getTermsAndConditions(true);
                this.optionalCampaigns = this.getTermsAndConditions(false);

                this.lcToggleCampaigns(false);

                for (const campaign in this.optionalCampaigns) {
                    if (this.optionalCampaigns.hasOwnProperty(campaign)) {
                        this.selectedPartners[campaign] = [];
                    }
                }

                this.selectedPartners.splice();
            }
        }
    },
    created() {
        this.logger = new Logger();
    },
    methods: {
        ...BaseTheme.methods,

        ...mapActions('leadcentre', {
            lcToggleGivenCampaigns : 'toggleCampaigns'
        }),

        /**
         * Get consents for each campaign
         * If required is true, only the consent for mandatory campaigns will be returned
         *
         * @param required
         */
        getTermsAndConditions(required) {
            const campaigns = this.lcGetCampaigns;

            let campaignList = {};
            let hashes       = {};

            campaigns.forEach(campaign => {
                let hash = CryptoJS_SHA256(campaign.consent.text);

                if (campaign.consent.required !== required) {
                    return;
                }

                // save hash of each consent so there are no duplicate sentences
                if (hashes.hasOwnProperty(hash)) {
                    campaignList[hash].ids.push(campaign.id);
                } else {
                    hashes[hash]       = campaign.consent.text;
                    campaignList[hash] = {campaign : campaign.consent.text, ids : [campaign.id]};
                }
            });

            return campaignList;
        },

        /**
         * Toggle a mandatory campaign
         *
         * @param campaignId
         */
        toggleRequiredCampaign(campaignId) {
            const selectedIndex = this.selectedRequiredCampaigns.indexOf(campaignId);

            if (selectedIndex >= 0) {
                this.selectedRequiredCampaigns.splice(selectedIndex, 1);
            } else {
                this.selectedRequiredCampaigns.push(campaignId);
            }
        },

        /**
         * Toggle an optional campaign
         *
         * @param campaignId
         * @param key
         */
        toggleOptionalCampaign(campaignId, key) {
            const selectedIndex = this.selectedOptionalCampaigns.indexOf(key);

            if (selectedIndex >= 0) {
                this.selectedOptionalCampaigns.splice(selectedIndex, 1);
            } else {
                this.selectedOptionalCampaigns.push(key);
            }

            // reset selected partners for current campaign
            this.selectedPartners[key] = [];

            // either select all partners or keep it empty
            if (!(selectedIndex >= 0)) {
                for (const partner of this.storeGetPartners) {
                    if (this.selectedPartners[key].indexOf(partner.value) === -1) {
                        this.selectedPartners[key].push(partner.value);
                    }
                }
            }
            // toggle current campaign according to partners selected
            this.lcToggleGivenCampaigns({campaignIds : campaignId, isSelected : !(selectedIndex >= 0)});
        },

        /**
         * Returns whether a mandatory campaign is selected
         *
         * @param campaignId
         *
         * @returns {boolean}
         */
        isRequiredCampaignSelected(campaignId) {
            return this.selectedRequiredCampaigns.indexOf(campaignId) >= 0;
        },

        /**
         * Returns whether an optional campaign is selected
         *
         * @param partnerCampaignId
         *
         * @returns {boolean}
         */
        isOptionalCampaignSelected(partnerCampaignId) {
            return (this.selectedOptionalCampaigns.indexOf(partnerCampaignId) >= 0);
        },

        /**
         * Toggle all campaigns when the "Select All" checkbox is checked
         *
         * @param event
         */
        selectAll(event) {
            if (event.target.checked) {
                return this.selectAllCampaigns();
            }

            this.deselectAllCampaigns();
        },

        /**
         * Select all required and optional campaigns
         */
        selectAllCampaigns() {
            for (const campaign in this.requiredCampaigns) {
                if (this.requiredCampaigns.hasOwnProperty(campaign)) {
                    const selectedIndex = this.selectedRequiredCampaigns.indexOf(this.requiredCampaigns[campaign].ids);

                    if (selectedIndex === -1) {
                        this.toggleRequiredCampaign(this.requiredCampaigns[campaign].ids);
                    }
                }
            }

            if (this.showOptionalCheckbox === false) {
                return;
            }

            for (const campaign in this.optionalCampaigns) {
                if (this.optionalCampaigns.hasOwnProperty(campaign)) {
                    const selectedIndex = this.selectedOptionalCampaigns.indexOf(campaign);

                    if (selectedIndex === -1) {
                        this.toggleOptionalCampaign(this.optionalCampaigns[campaign].ids, campaign);
                    }
                }
            }
        },

        /**
         * Deselect all required and optional campaigns
         */
        deselectAllCampaigns() {
            for (const campaign in this.requiredCampaigns) {
                if (this.requiredCampaigns.hasOwnProperty(campaign)) {
                    const selectedIndex = this.selectedRequiredCampaigns.indexOf(this.requiredCampaigns[campaign].ids);

                    if (selectedIndex >= 0) {
                        this.toggleRequiredCampaign(this.requiredCampaigns[campaign].ids);
                    }
                }
            }

            if (this.showOptionalCheckbox === false) {
                return;
            }

            for (const campaign in this.optionalCampaigns) {
                if (this.optionalCampaigns.hasOwnProperty(campaign)) {
                    const selectedIndex = this.selectedOptionalCampaigns.indexOf(campaign);

                    if (selectedIndex >= 0) {
                        this.toggleOptionalCampaign(this.optionalCampaigns[campaign].ids, campaign);
                    }
                }
            }
        },

        /**
         * Check if partner vertical is selected for current campaign
         *
         * @param partner
         * @returns {boolean}
         */
        isPartnerSelected(partner) {
            if (typeof (this.selectedPartners[this.currentCampaign]) === 'undefined') {
                this.selectedPartners[this.currentCampaign] = [];
            }

            return this.selectedPartners[this.currentCampaign].indexOf(partner) >= 0;
        },

        isAnySelected() {
            return this.allPartnersSelected
				|| this.selectedOptionalCampaigns.length > 0
				|| this.selectedRequiredCampaigns.length > 0;
        },

        /**
         * Open partner modal for a specific campaign
         * We can have multiple partner campaigns, each with its checkbox and partners
         *
         * @param event
         * @param ids
         * @param key
         */
        openCampaignPartnersDisclosure(event, ids, key) {
            if (this.preventPartnersDisclosure(event.target)) {
                event.stopPropagation();

                return;
            }

            if (this.showOptionalCheckbox === false) {
                this.lcToggleCampaigns(false);
                this.showOptionalCheckbox = true;
            }

            this.currentCampaignIds  = ids;
            this.currentCampaign     = key;
            this.showPartners        = true;
            // this.allPartnersSelected = (this.selectedPartners[this.currentCampaign].length === this.storeGetPartners.length);
            this.allPartnersSelected = false;
            this.selectedPartners = [];
        },

        /**
         * Close partner modal for a campaign
         *
         * @param event
         */
        closeCampaignPartnersDisclosure(event) {
            if (this.preventPartnersDisclosure(event.target)) {
                event.stopPropagation();

                return;
            }

            // trigger event for closing the partners modal
            this.logger.dataLayerPush({'event': 'lcClosePartnersModal'});

            this.showPartners = false;
        },

        /**
         * Toggle a partner vertical on the partners modal
         *
         * @param event
         */
		toggleVertical(event) {
            const partner       = event.target.value;
            const selectedIndex = this.selectedPartners[this.currentCampaign].indexOf(partner);

			// toggle the partner for the current campaign
			if (selectedIndex >= 0) {
				this.selectedPartners[this.currentCampaign].splice(selectedIndex, 1);
			} else {
				this.selectedPartners[this.currentCampaign].push(partner);
			}
		},

        /**
         * Toggle a partner vertical on the partners modal
         *
         * @param event
         */
        togglePartner(event) {
            const partner       = event.target.value;
            const selectedIndex = this.selectedPartners[this.currentCampaign].indexOf(partner);

            // toggle the partner for the current campaign
            if (selectedIndex >= 0) {
                this.selectedPartners[this.currentCampaign].splice(selectedIndex, 1);
            } else {
                this.selectedPartners[this.currentCampaign].push(partner);
            }

            // check if all partners ended up selected and toggle current campaign accordingly
            this.hasPartnersSelected = this.selectedPartners[this.currentCampaign].length > 0;
            this.allPartnersSelected = (this.selectedPartners[this.currentCampaign].length === this.storeGetPartners.length);
            this.lcToggleGivenCampaigns({campaignIds : this.currentCampaignIds, isSelected : this.allPartnersSelected});
            const selectedCampaignIndex = this.selectedOptionalCampaigns.indexOf(this.currentCampaign);

            if (this.hasPartnersSelected) {
                this.addSelectedOptionalCampaign(this.currentCampaign);
            } else if (selectedCampaignIndex !== -1) {
                this.selectedOptionalCampaigns.splice(selectedCampaignIndex, 1);
            }
        },

        /**
         * Toggle all partner verticals on the partners modal
         */
        toggleAllPartners() {
            this.allPartnersSelected = !this.allPartnersSelected;

            // reset selected partners for current campaign
            this.selectedPartners[this.currentCampaign] = [];

            // either select all partners or keep it empty
            if (this.allPartnersSelected) {
                for (const partner of this.storeGetPartners) {
                    if (this.selectedPartners[this.currentCampaign].indexOf(partner.value) === -1) {
                        this.selectedPartners[this.currentCampaign].push(partner.value);
                    }
                }
            }

            // toggle current campaign according to partners selected
            this.lcToggleGivenCampaigns({campaignIds : this.currentCampaignIds, isSelected : this.allPartnersSelected});
            const selectedCampaignIndex = this.selectedOptionalCampaigns.indexOf(this.currentCampaign);

            if (this.allPartnersSelected) {
                this.addSelectedOptionalCampaign(this.currentCampaign);
            } else if (selectedCampaignIndex !== -1) {
                this.selectedOptionalCampaigns.splice(selectedCampaignIndex, 1);
            }
        },

        /**
         * Add a selected optional campaign to the list without repeating
         *
         * @param campaign
         */
        addSelectedOptionalCampaign(campaign) {
            if (this.selectedOptionalCampaigns.includes(campaign)) {
                return;
            }

            this.selectedOptionalCampaigns.push(campaign);
        },
    }
};
